import { default as abuseReportsJykkIFn4fMMeta } from "/home/admin/www/en.pronouns.page/release/20240916201321/pages/admin/abuseReports.vue?macro=true";
import { default as _91id_93j1TkUsymRkMeta } from "/home/admin/www/en.pronouns.page/release/20240916201321/pages/admin/auditLog/[username]/[id].vue?macro=true";
import { default as indexCDzx8wUbaKMeta } from "/home/admin/www/en.pronouns.page/release/20240916201321/pages/admin/index.vue?macro=true";
import { default as moderationtnaS9bo1LLMeta } from "/home/admin/www/en.pronouns.page/release/20240916201321/pages/admin/moderation.vue?macro=true";
import { default as pendingBansI8H0B3leS6Meta } from "/home/admin/www/en.pronouns.page/release/20240916201321/pages/admin/pendingBans.vue?macro=true";
import { default as profileswhPIOoy6WIMeta } from "/home/admin/www/en.pronouns.page/release/20240916201321/pages/admin/profiles.vue?macro=true";
import { default as indexlOXlH3Mam3Meta } from "/home/admin/www/en.pronouns.page/release/20240916201321/pages/admin/timesheets/index.vue?macro=true";
import { default as overviewlv2gLJhRFrMeta } from "/home/admin/www/en.pronouns.page/release/20240916201321/pages/admin/timesheets/overview.vue?macro=true";
import { default as awaitingLokCc0CxsJMeta } from "/home/admin/www/en.pronouns.page/release/20240916201321/pages/admin/translations/awaiting.vue?macro=true";
import { default as missingCcIVktPzRGMeta } from "/home/admin/www/en.pronouns.page/release/20240916201321/pages/admin/translations/missing.vue?macro=true";
import { default as usersjuAR51ZXkhMeta } from "/home/admin/www/en.pronouns.page/release/20240916201321/pages/admin/users.vue?macro=true";
import { default as apimwdBfxziPmMeta } from "/home/admin/www/en.pronouns.page/release/20240916201321/pages/api.vue?macro=true";
import { default as _91slug_93ShUmrVntg2Meta } from "/home/admin/www/en.pronouns.page/release/20240916201321/pages/blog/[slug].vue?macro=true";
import { default as index6hl2BTvfydMeta } from "/home/admin/www/en.pronouns.page/release/20240916201321/pages/blog/index.vue?macro=true";
import { default as _91_91year_93_93CP11SSl1TmMeta } from "/home/admin/www/en.pronouns.page/release/20240916201321/pages/calendar/[[year]].vue?macro=true";
import { default as _91year_93_45_91month_93_45_91day_93zmjgdUXYvvMeta } from "/home/admin/www/en.pronouns.page/release/20240916201321/pages/calendar/[year]-[month]-[day].vue?macro=true";
import { default as adminadxxBWE06HMeta } from "/home/admin/www/en.pronouns.page/release/20240916201321/pages/census/admin.vue?macro=true";
import { default as indextCVjIGweZ2Meta } from "/home/admin/www/en.pronouns.page/release/20240916201321/pages/census/index.vue?macro=true";
import { default as contactHa0DxIA0nzMeta } from "/home/admin/www/en.pronouns.page/release/20240916201321/pages/contact.vue?macro=true";
import { default as designCMXXa0hViQMeta } from "/home/admin/www/en.pronouns.page/release/20240916201321/pages/design.vue?macro=true";
import { default as englishqk2hKYcdE4Meta } from "/home/admin/www/en.pronouns.page/release/20240916201321/pages/english.vue?macro=true";
import { default as faq7XMADs34dtMeta } from "/home/admin/www/en.pronouns.page/release/20240916201321/pages/faq.vue?macro=true";
import { default as inclusiveg1M0Sv9wrPMeta } from "/home/admin/www/en.pronouns.page/release/20240916201321/pages/inclusive.vue?macro=true";
import { default as indexvK3phkB8IFMeta } from "/home/admin/www/en.pronouns.page/release/20240916201321/pages/index.vue?macro=true";
import { default as licenseoif4KwoJYKMeta } from "/home/admin/www/en.pronouns.page/release/20240916201321/pages/license.vue?macro=true";
import { default as academicpBp6imbehdMeta } from "/home/admin/www/en.pronouns.page/release/20240916201321/pages/links/academic.vue?macro=true";
import { default as indexeEglnetozRMeta } from "/home/admin/www/en.pronouns.page/release/20240916201321/pages/links/index.vue?macro=true";
import { default as mediaiiRpdVlVznMeta } from "/home/admin/www/en.pronouns.page/release/20240916201321/pages/links/media.vue?macro=true";
import { default as translinguisticsWCI3IYEvRiMeta } from "/home/admin/www/en.pronouns.page/release/20240916201321/pages/links/translinguistics.vue?macro=true";
import { default as zineA44Ykt0EOrMeta } from "/home/admin/www/en.pronouns.page/release/20240916201321/pages/links/zine.vue?macro=true";
import { default as namesZODh5uVx2FMeta } from "/home/admin/www/en.pronouns.page/release/20240916201321/pages/names.vue?macro=true";
import { default as index3qCVJiu7ruMeta } from "/home/admin/www/en.pronouns.page/release/20240916201321/pages/nouns/index.vue?macro=true";
import { default as templatesEdva3x9rbEMeta } from "/home/admin/www/en.pronouns.page/release/20240916201321/pages/nouns/templates.vue?macro=true";
import { default as peopleWOVK84rw1cMeta } from "/home/admin/www/en.pronouns.page/release/20240916201321/pages/people.vue?macro=true";
import { default as privacyXhChuynxduMeta } from "/home/admin/www/en.pronouns.page/release/20240916201321/pages/privacy.vue?macro=true";
import { default as _91username_93GWjJLxIq0bMeta } from "/home/admin/www/en.pronouns.page/release/20240916201321/pages/profile/[username].vue?macro=true";
import { default as card_45_91username_93CzMK5yvSvBMeta } from "/home/admin/www/en.pronouns.page/release/20240916201321/pages/profile/card-[username].vue?macro=true";
import { default as editorH01LTp1Gs5Meta } from "/home/admin/www/en.pronouns.page/release/20240916201321/pages/profile/editor.vue?macro=true";
import { default as anyrEJOdt6RLTMeta } from "/home/admin/www/en.pronouns.page/release/20240916201321/pages/pronouns/any.vue?macro=true";
import { default as avoiding4Lzvj3vAEyMeta } from "/home/admin/www/en.pronouns.page/release/20240916201321/pages/pronouns/avoiding.vue?macro=true";
import { default as indexc02b3mmgMEMeta } from "/home/admin/www/en.pronouns.page/release/20240916201321/pages/pronouns/index.vue?macro=true";
import { default as mirrorgdufCJdVBBMeta } from "/home/admin/www/en.pronouns.page/release/20240916201321/pages/pronouns/mirror.vue?macro=true";
import { default as pronouneWDYmehZpOMeta } from "/home/admin/www/en.pronouns.page/release/20240916201321/pages/pronouns/pronoun.vue?macro=true";
import { default as sourcesBMIssnXQ3gMeta } from "/home/admin/www/en.pronouns.page/release/20240916201321/pages/sources.vue?macro=true";
import { default as teamI27XakiCdYMeta } from "/home/admin/www/en.pronouns.page/release/20240916201321/pages/team.vue?macro=true";
import { default as terminologyJjhhrqpE6iMeta } from "/home/admin/www/en.pronouns.page/release/20240916201321/pages/terminology.vue?macro=true";
import { default as termsTutfGhWUrnMeta } from "/home/admin/www/en.pronouns.page/release/20240916201321/pages/terms.vue?macro=true";
import { default as userSpVi6alCj0Meta } from "/home/admin/www/en.pronouns.page/release/20240916201321/pages/user.vue?macro=true";
import { default as workshopsVTllYytK54Meta } from "/home/admin/www/en.pronouns.page/release/20240916201321/pages/workshops.vue?macro=true";
export default [
  {
    name: "admin-abuseReports",
    path: "/admin/abuse-reports",
    meta: abuseReportsJykkIFn4fMMeta || {},
    component: () => import("/home/admin/www/en.pronouns.page/release/20240916201321/pages/admin/abuseReports.vue").then(m => m.default || m)
  },
  {
    name: "admin-auditLog-username-id",
    path: "/admin/auditLog/:username()/:id()",
    component: () => import("/home/admin/www/en.pronouns.page/release/20240916201321/pages/admin/auditLog/[username]/[id].vue").then(m => m.default || m)
  },
  {
    name: "admin",
    path: "/admin",
    component: () => import("/home/admin/www/en.pronouns.page/release/20240916201321/pages/admin/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-moderation",
    path: "/admin/moderation",
    component: () => import("/home/admin/www/en.pronouns.page/release/20240916201321/pages/admin/moderation.vue").then(m => m.default || m)
  },
  {
    name: "admin-pendingBans",
    path: "/admin/pending-bans",
    meta: pendingBansI8H0B3leS6Meta || {},
    component: () => import("/home/admin/www/en.pronouns.page/release/20240916201321/pages/admin/pendingBans.vue").then(m => m.default || m)
  },
  {
    name: "admin-profiles",
    path: "/admin/profiles",
    component: () => import("/home/admin/www/en.pronouns.page/release/20240916201321/pages/admin/profiles.vue").then(m => m.default || m)
  },
  {
    name: "admin-timesheets",
    path: "/admin/timesheets",
    component: () => import("/home/admin/www/en.pronouns.page/release/20240916201321/pages/admin/timesheets/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-timesheets-overview",
    path: "/admin/timesheets/overview",
    component: () => import("/home/admin/www/en.pronouns.page/release/20240916201321/pages/admin/timesheets/overview.vue").then(m => m.default || m)
  },
  {
    name: "admin-translations-awaiting",
    path: "/admin/translations/awaiting",
    component: () => import("/home/admin/www/en.pronouns.page/release/20240916201321/pages/admin/translations/awaiting.vue").then(m => m.default || m)
  },
  {
    name: "admin-translations-missing",
    path: "/admin/translations/missing",
    component: () => import("/home/admin/www/en.pronouns.page/release/20240916201321/pages/admin/translations/missing.vue").then(m => m.default || m)
  },
  {
    name: "admin-users",
    path: "/admin/users",
    component: () => import("/home/admin/www/en.pronouns.page/release/20240916201321/pages/admin/users.vue").then(m => m.default || m)
  },
  {
    name: "api",
    path: "/api",
    meta: apimwdBfxziPmMeta || {},
    component: () => import("/home/admin/www/en.pronouns.page/release/20240916201321/pages/api.vue").then(m => m.default || m)
  },
  {
    name: "blog-slug",
    path: "/blog/:slug()",
    meta: _91slug_93ShUmrVntg2Meta || {},
    component: () => import("/home/admin/www/en.pronouns.page/release/20240916201321/pages/blog/[slug].vue").then(m => m.default || m)
  },
  {
    name: "blog",
    path: "/blog",
    meta: index6hl2BTvfydMeta || {},
    component: () => import("/home/admin/www/en.pronouns.page/release/20240916201321/pages/blog/index.vue").then(m => m.default || m)
  },
  {
    name: "calendar",
    path: "/calendar/:year?",
    meta: _91_91year_93_93CP11SSl1TmMeta || {},
    component: () => import("/home/admin/www/en.pronouns.page/release/20240916201321/pages/calendar/[[year]].vue").then(m => m.default || m)
  },
  {
    name: "calendarDay",
    path: "/calendar/:year()-:month()-:day()",
    meta: _91year_93_45_91month_93_45_91day_93zmjgdUXYvvMeta || {},
    component: () => import("/home/admin/www/en.pronouns.page/release/20240916201321/pages/calendar/[year]-[month]-[day].vue").then(m => m.default || m)
  },
  {
    name: "census-admin",
    path: "/census/admin",
    meta: adminadxxBWE06HMeta || {},
    component: () => import("/home/admin/www/en.pronouns.page/release/20240916201321/pages/census/admin.vue").then(m => m.default || m)
  },
  {
    name: "census",
    path: "/census",
    meta: indextCVjIGweZ2Meta || {},
    component: () => import("/home/admin/www/en.pronouns.page/release/20240916201321/pages/census/index.vue").then(m => m.default || m)
  },
  {
    name: "contact",
    path: "/contact",
    meta: contactHa0DxIA0nzMeta || {},
    component: () => import("/home/admin/www/en.pronouns.page/release/20240916201321/pages/contact.vue").then(m => m.default || m)
  },
  {
    name: "design",
    path: "/design",
    component: () => import("/home/admin/www/en.pronouns.page/release/20240916201321/pages/design.vue").then(m => m.default || m)
  },
  {
    name: "english",
    path: "/english",
    meta: englishqk2hKYcdE4Meta || {},
    component: () => import("/home/admin/www/en.pronouns.page/release/20240916201321/pages/english.vue").then(m => m.default || m)
  },
  {
    name: "faq",
    path: "/faq",
    meta: faq7XMADs34dtMeta || {},
    component: () => import("/home/admin/www/en.pronouns.page/release/20240916201321/pages/faq.vue").then(m => m.default || m)
  },
  {
    name: "inclusive",
    path: "/inclusive",
    meta: inclusiveg1M0Sv9wrPMeta || {},
    component: () => import("/home/admin/www/en.pronouns.page/release/20240916201321/pages/inclusive.vue").then(m => m.default || m)
  },
  {
    name: "index",
    path: "/",
    component: () => import("/home/admin/www/en.pronouns.page/release/20240916201321/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "license",
    path: "/license",
    component: () => import("/home/admin/www/en.pronouns.page/release/20240916201321/pages/license.vue").then(m => m.default || m)
  },
  {
    name: "links-academic",
    path: "/links/academic",
    meta: academicpBp6imbehdMeta || {},
    component: () => import("/home/admin/www/en.pronouns.page/release/20240916201321/pages/links/academic.vue").then(m => m.default || m)
  },
  {
    name: "links",
    path: "/links",
    meta: indexeEglnetozRMeta || {},
    component: () => import("/home/admin/www/en.pronouns.page/release/20240916201321/pages/links/index.vue").then(m => m.default || m)
  },
  {
    name: "links-media",
    path: "/links/media",
    meta: mediaiiRpdVlVznMeta || {},
    component: () => import("/home/admin/www/en.pronouns.page/release/20240916201321/pages/links/media.vue").then(m => m.default || m)
  },
  {
    name: "links-translinguistics",
    path: "/links/translinguistics",
    meta: translinguisticsWCI3IYEvRiMeta || {},
    component: () => import("/home/admin/www/en.pronouns.page/release/20240916201321/pages/links/translinguistics.vue").then(m => m.default || m)
  },
  {
    name: "links-zine",
    path: "/links/zine",
    meta: zineA44Ykt0EOrMeta || {},
    component: () => import("/home/admin/www/en.pronouns.page/release/20240916201321/pages/links/zine.vue").then(m => m.default || m)
  },
  {
    name: "names",
    path: "/names",
    meta: namesZODh5uVx2FMeta || {},
    component: () => import("/home/admin/www/en.pronouns.page/release/20240916201321/pages/names.vue").then(m => m.default || m)
  },
  {
    name: "nouns",
    path: "/nouns",
    meta: index3qCVJiu7ruMeta || {},
    component: () => import("/home/admin/www/en.pronouns.page/release/20240916201321/pages/nouns/index.vue").then(m => m.default || m)
  },
  {
    name: "nouns-templates",
    path: "/nouns/templates",
    meta: templatesEdva3x9rbEMeta || {},
    component: () => import("/home/admin/www/en.pronouns.page/release/20240916201321/pages/nouns/templates.vue").then(m => m.default || m)
  },
  {
    name: "people",
    path: "/people",
    meta: peopleWOVK84rw1cMeta || {},
    component: () => import("/home/admin/www/en.pronouns.page/release/20240916201321/pages/people.vue").then(m => m.default || m)
  },
  {
    name: "privacy",
    path: "/privacy",
    meta: privacyXhChuynxduMeta || {},
    component: () => import("/home/admin/www/en.pronouns.page/release/20240916201321/pages/privacy.vue").then(m => m.default || m)
  },
  {
    name: "profile-username",
    path: "/profile/:username()",
    meta: _91username_93GWjJLxIq0bMeta || {},
    component: () => import("/home/admin/www/en.pronouns.page/release/20240916201321/pages/profile/[username].vue").then(m => m.default || m)
  },
  {
    name: "profile-card-username",
    path: "/profile/card-:username()",
    meta: card_45_91username_93CzMK5yvSvBMeta || {},
    component: () => import("/home/admin/www/en.pronouns.page/release/20240916201321/pages/profile/card-[username].vue").then(m => m.default || m)
  },
  {
    name: "profile-editor",
    path: "/profile/editor",
    meta: editorH01LTp1Gs5Meta || {},
    component: () => import("/home/admin/www/en.pronouns.page/release/20240916201321/pages/profile/editor.vue").then(m => m.default || m)
  },
  {
    name: "pronouns-any",
    path: "/pronouns/any",
    meta: anyrEJOdt6RLTMeta || {},
    component: () => import("/home/admin/www/en.pronouns.page/release/20240916201321/pages/pronouns/any.vue").then(m => m.default || m)
  },
  {
    name: "pronouns-avoiding",
    path: "/pronouns/avoiding",
    meta: avoiding4Lzvj3vAEyMeta || {},
    component: () => import("/home/admin/www/en.pronouns.page/release/20240916201321/pages/pronouns/avoiding.vue").then(m => m.default || m)
  },
  {
    name: "pronouns",
    path: "/pronouns",
    meta: indexc02b3mmgMEMeta || {},
    component: () => import("/home/admin/www/en.pronouns.page/release/20240916201321/pages/pronouns/index.vue").then(m => m.default || m)
  },
  {
    name: "pronouns-mirror",
    path: "/pronouns/mirror",
    meta: mirrorgdufCJdVBBMeta || {},
    component: () => import("/home/admin/www/en.pronouns.page/release/20240916201321/pages/pronouns/mirror.vue").then(m => m.default || m)
  },
  {
    name: "all",
    path: "/:path(.*)",
    meta: pronouneWDYmehZpOMeta || {},
    component: () => import("/home/admin/www/en.pronouns.page/release/20240916201321/pages/pronouns/pronoun.vue").then(m => m.default || m)
  },
  {
    name: "sources",
    path: "/sources",
    meta: sourcesBMIssnXQ3gMeta || {},
    component: () => import("/home/admin/www/en.pronouns.page/release/20240916201321/pages/sources.vue").then(m => m.default || m)
  },
  {
    name: "team",
    path: "/team",
    meta: teamI27XakiCdYMeta || {},
    component: () => import("/home/admin/www/en.pronouns.page/release/20240916201321/pages/team.vue").then(m => m.default || m)
  },
  {
    name: "terminology",
    path: "/terminology",
    meta: terminologyJjhhrqpE6iMeta || {},
    component: () => import("/home/admin/www/en.pronouns.page/release/20240916201321/pages/terminology.vue").then(m => m.default || m)
  },
  {
    name: "terms",
    path: "/terms",
    meta: termsTutfGhWUrnMeta || {},
    component: () => import("/home/admin/www/en.pronouns.page/release/20240916201321/pages/terms.vue").then(m => m.default || m)
  },
  {
    name: "user",
    path: "/user",
    meta: userSpVi6alCj0Meta || {},
    component: () => import("/home/admin/www/en.pronouns.page/release/20240916201321/pages/user.vue").then(m => m.default || m)
  },
  {
    name: "workshops",
    path: "/workshops",
    meta: workshopsVTllYytK54Meta || {},
    component: () => import("/home/admin/www/en.pronouns.page/release/20240916201321/pages/workshops.vue").then(m => m.default || m)
  }
]